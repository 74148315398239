<template>
  <svg viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.39287 9.84212C3.98959 9.84035 4.56372 9.61381 5.00088 9.20765L9.2716 11.648C9.12851 12.2071 9.19159 12.7991 9.4493 13.3156C9.707 13.832 10.1421 14.2384 10.6749 14.4602C11.2077 14.6821 11.8026 14.7047 12.3507 14.5238C12.8988 14.3429 13.3634 13.9706 13.6595 13.4751C13.9556 12.9797 14.0633 12.3942 13.9629 11.8258C13.8626 11.2574 13.5609 10.7442 13.113 10.3801C12.6652 10.016 12.1012 9.82531 11.5243 9.84303C10.9474 9.86076 10.3962 10.0857 9.97157 10.4766L5.70084 8.03627C5.74587 7.86571 5.77043 7.68902 5.77452 7.51232L9.9702 5.1143C10.3742 5.48187 10.8923 5.69931 11.4376 5.73016C11.9829 5.761 12.5223 5.60337 12.9651 5.28371C13.408 4.96405 13.7275 4.5018 13.8699 3.97452C14.0124 3.44724 13.9692 2.88701 13.7476 2.38781C13.5259 1.88862 13.1394 1.48082 12.6527 1.23285C12.1661 0.984877 11.6089 0.91181 11.0748 1.02591C10.5407 1.14001 10.062 1.43433 9.71918 1.85949C9.37632 2.28466 9.19011 2.8148 9.19178 3.36098C9.19451 3.55747 9.22112 3.75326 9.2716 3.94292L5.39384 6.15811C5.16896 5.81021 4.85745 5.52681 4.4899 5.33573C4.12236 5.14466 3.71143 5.05249 3.29748 5.06829C2.88354 5.08408 2.48083 5.20729 2.12891 5.42581C1.77699 5.64434 1.48798 5.95066 1.29026 6.31467C1.09255 6.67869 0.992933 7.08788 1.00121 7.50204C1.00948 7.9162 1.12536 8.32109 1.33746 8.67691C1.54956 9.03274 1.85058 9.32727 2.21095 9.53156C2.57132 9.73585 2.97863 9.84287 3.39287 9.84212Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconShare',
}
</script>
